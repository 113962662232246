
import { onMounted, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs(translate("افزونه ها"), []);
    });

    return {
      translate,
    };
  },
});
